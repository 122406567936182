var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app-bar",
    { attrs: { app: "", "clipped-left": "", color: "primary" } },
    [
      _c("v-app-bar-nav-icon", {
        attrs: { dark: "" },
        on: {
          click: function($event) {
            return _vm.$emit("toggleNav")
          }
        }
      }),
      _c(
        "div",
        {
          staticClass:
            "title ml-3 mr-5 mt-2 hidden-sm-and-down align-center d-flex"
        },
        [
          _c("span", [
            _c("img", { attrs: { src: _vm.$config("settings.appLogoIcon") } })
          ]),
          _c("span", { staticClass: "ml-2 hidden-sm-and-down" }, [
            _c("img", {
              staticClass: "mt-1",
              attrs: { src: _vm.$config("settings.appLogoText"), height: "35" }
            })
          ])
        ]
      ),
      _c("v-spacer"),
      _c("psi-action-drop-down", {
        attrs: {
          large: "",
          icon: "mdi-account-circle",
          items: _vm.userMenu,
          heading: _vm.$_.get(_vm.user, "name", ""),
          subheading: "Pacific Screening"
        },
        on: { action: _vm.menuClicked }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }