<template>
    <v-app-bar app clipped-left color="primary">
        <v-app-bar-nav-icon @click="$emit('toggleNav')" dark></v-app-bar-nav-icon>
        <div class="title ml-3 mr-5 mt-2 hidden-sm-and-down align-center d-flex">
            <span>
                <img :src="$config('settings.appLogoIcon')" />
            </span>
            <span class="ml-2 hidden-sm-and-down">
                <img :src="$config('settings.appLogoText')" class="mt-1" height="35" />
            </span>
        </div>
        <!-- <v-text-field
            solo
            flat
            hide-details
            dark
            label="Search"
            color="white"
            background-color="primary lighten-1"
            class="blue--text"
            prepend-inner-icon="search"
        ></v-text-field>-->
        <v-spacer></v-spacer>

        <psi-action-drop-down
            large
            icon="mdi-account-circle"
            :items="userMenu"
            :heading="$_.get(user,'name','')"
            subheading="Pacific Screening"
            @action="menuClicked"
        ></psi-action-drop-down>
    </v-app-bar>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
    name: "app-header",
    data() {
        return {
            userMenu: [
                {
                    title: "My Orders",
                    icon: "mdi-clipboard-account",
                    class: "primary--text text--darken-4",
                    to: {
                        path: "/messages/orders",
                        params: { inputUser: this.user },
                    },
                },
                {
                    divider: true,
                },
                {
                    title: "Logout",
                    icon: "mdi-logout",
                    class: "error--text",
                },
            ],
        };
    },
    computed: {
        ...mapGetters("User", ["user"]),
    },
    methods: {
        ...mapActions("User", ["logout"]),
        menuClicked(item) {
            switch (item.title) {
                case "Logout":
                    this.logout();
                    break;
            }
        },
    },
    mounted() {},
};
</script>

<style scoped>
</style>